import React, {useReducer} from 'react';
import {renderRoutes} from 'react-router-config';
import {useDispatch} from 'react-redux';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Helmet} from 'react-helmet-async';
import {object} from 'prop-types';
import {updateRefereeGroup, setRefereeGroupHeader} from 'app/routes/refereeGroup/RefereeGroupPageActions';
import {RefereeGroupPageReducer} from 'app/routes/refereeGroup/RefereeGroupPageReducer';
import {TabBar} from 'app/components/tab/TabBar';
import {useParams} from 'react-router';
import {useFetchData, useRestoreData} from 'app/hooks/dataHooks';
import {ErrorHandler} from 'app/components/error/ErrorHandler';

const RefereeGroupPage = ({route}) => {
  const {restoredData} = useRestoreData('RefereeGroupPage');
  const {groupSlug} = useParams();

  const initialState = restoredData ?? {};
  const [state, dispatch] = useReducer(RefereeGroupPageReducer, initialState);
  const dispatchRedux = useDispatch();

  useFetchData(state, () => null, 'RefereeGroupPage', true);

  const updateRefereeGroupPageState = data => {
    if (data) {
      if (state.slug !== data.slug) {
        dispatch(updateRefereeGroup(data));
      }
      setRefereeGroupHeader(dispatchRedux, data);
    } else {
      setRefereeGroupHeader(dispatchRedux, state);
    }
  };

  const refereeGroupName = state.name;
  const defaultTitle = `Schiedsrichtergruppe ${refereeGroupName}${TITLE_POSTFIX}`;
  const titleTemplate = `%s${defaultTitle}`;
  const districtName = Object.keys(state).length > 0 ? state.name : '';
  const defaultMetaDescription = `Alle aktiven Schiedsrichter mit deren Daten und Statistiken der Gruppe ${refereeGroupName} aus der Region ${districtName}.`;
  const meta = [{name: 'robots', content: 'noindex, follow'}];

  const TabNames = [
    {
      name: 'Saison',
      url: `/referee-group/${groupSlug}`,
    },
    {
      name: 'Chronik',
      url: `/referee-group/${groupSlug}/history`,
    },
    {
      name: 'Ausschuss',
      url: `/referee-group/${groupSlug}/committee`,
    },
  ];

  return (
    <>
      <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} meta={meta} />
      <TabBar tabs={TabNames} />
      <ErrorHandler subroute={true}>
        {renderRoutes(route.routes, {updateRefereeGroupPageState, defaultMetaDescription, dispatchRedux})}
      </ErrorHandler>
    </>
  );
};

RefereeGroupPage.propTypes = {
  route: object.isRequired,
};

export default RefereeGroupPage;
