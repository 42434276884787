import {fetchNextApiCall, api} from 'app/services/api';
import {changeHeaderText} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException, parseLinkHeader} from 'app/helpers/apiHelpers';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const getRefereeGroupSeason = groupSlug => {
  return api.get(`/referee-groups/${groupSlug}/referees`);
};

const getRefereeGroupHistory = groupSlug => {
  return api.get(`/referee-groups/${groupSlug}/refereesAlltime`);
};

const getRefereeGroupCommittee = groupSlug => {
  return api.get(`/referee-groups/${groupSlug}/committee`);
};

// REFEREE_GROUP_PAGE
function updateRefereeGroup(data) {
  return {
    type: 'REFEREE_GROUP_UPDATE',
    data,
  };
}

function setRefereeGroupHeader(dispatch, refereeGroup) {
  const parent = {
    name: refereeGroup.name,
    entity: 'refereeGroup',
    slugs: {
      groupSlug: refereeGroup.slug,
    },
  };
  dispatch(
    changeHeaderText({
      title: 'Schiedsrichtergruppe',
      parent,
    })
  );
}

// REFEREE_GROUP_SEASON_PAGE
function fetchingGroupSeason() {
  return {
    type: 'REFEREE_GROUP_SEASON_FETCHING',
  };
}

function updateGroupSeason(data) {
  return {
    type: 'REFEREE_GROUP_SEASON_UPDATE',
    data,
  };
}

function errorGroupSeason(data) {
  return {
    type: 'REFEREE_GROUP_SEASON_ERROR',
    data,
  };
}

function fetchRefereeGroupSeasonSSR(slug) {
  return function (dispatch, getState) {
    return getRefereeGroupSeason(slug)
      .then(response => {
        const {refereeGroup, referees} = response.data;
        checkApiRedirect(refereeGroup.slug, slug, null, null, getState);
        dispatch(updateHistory('undefined', {store: 'RefereeGroupPage', data: refereeGroup}));
        setRefereeGroupHeader(dispatch, refereeGroup);
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'RefereeGroupSeasonPage',
            data: {items: referees, nextUrl, isFetching: false, error: null},
          })
        );
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatch(updateHttpErrorSubroute(errorData));
        }
      });
  };
}

function fetchRefereeGroupSeason(slug, updateRefereeGroupPageState, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      updateRefereeGroupPageState(null); // set header
      return;
    }
    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getRefereeGroupSeason(slug);
    dispatch(fetchingGroupSeason());
    return fetchFunc
      .then(response => {
        const {refereeGroup, referees} = response.data;
        checkApiRedirect(refereeGroup.slug, slug, null, null);
        updateRefereeGroupPageState(refereeGroup);
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateGroupSeason({items: referees, nextUrl}));
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          nextUrl ? dispatch(errorGroupSeason(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
        }
      });
  };
}

// REFEREE_GROUP_HISTORY_PAGE
function fetchingGroupHistory() {
  return {
    type: 'REFEREE_GROUP_HISTORY_FETCHING',
  };
}

function updateGroupHistory(data) {
  return {
    type: 'REFEREE_GROUP_HISTORY_UPDATE',
    data,
  };
}

function errorGroupHistory(data) {
  return {
    type: 'REFEREE_GROUP_HISTORY_ERROR',
    data,
  };
}

function fetchRefereeGroupHistorySSR(slug) {
  return function (dispatch, getState) {
    return getRefereeGroupHistory(slug)
      .then(response => {
        const {refereeGroup, referees} = response.data;
        checkApiRedirect(refereeGroup.slug, slug, null, null, getState);
        dispatch(updateHistory('undefined', {store: 'RefereeGroupPage', data: refereeGroup}));
        setRefereeGroupHeader(dispatch, refereeGroup);
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'RefereeGroupHistoryPage',
            data: {items: referees, nextUrl, isFetching: false, error: null},
          })
        );
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatch(updateHttpErrorSubroute(errorData));
        }
      });
  };
}

function fetchRefereeGroupHistory(slug, updateRefereeGroupPageState, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      updateRefereeGroupPageState(null); // set header
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getRefereeGroupHistory(slug);
    dispatch(fetchingGroupHistory());
    return fetchFunc
      .then(response => {
        const {refereeGroup, referees} = response.data;
        checkApiRedirect(refereeGroup.slug, slug, null, null);
        updateRefereeGroupPageState(refereeGroup);
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateGroupHistory({items: referees, nextUrl}));
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          nextUrl ? dispatch(errorGroupHistory(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
        }
      });
  };
}

// REFEREE_GROUP_COMMITTEE_PAGE
function fetchingGroupCommittee() {
  return {
    type: 'REFEREE_GROUP_COMMITTEE_FETCHING',
  };
}

function updateGroupCommittee(data) {
  return {
    type: 'REFEREE_GROUP_COMMITTEE_UPDATE',
    data,
  };
}

function fetchRefereeGroupCommitteeSSR(slug) {
  return function (dispatch, getState) {
    return getRefereeGroupCommittee(slug)
      .then(response => {
        const {refereeGroup, referees} = response.data;
        checkApiRedirect(refereeGroup.slug, slug, null, null, getState);
        dispatch(updateHistory('undefined', {store: 'RefereeGroupPage', data: refereeGroup}));
        setRefereeGroupHeader(dispatch, refereeGroup);
        dispatch(
          updateHistory('undefined', {
            store: 'RefereeGroupCommitteePage',
            data: {items: referees, isFetching: false},
          })
        );
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatch(updateHttpErrorSubroute(errorData));
        }
      });
  };
}

function fetchRefereeGroupCommittee(slug, updateRefereeGroupPageState, dispatchRedux) {
  return function (dispatch, state) {
    if (state.items.length || state.error) {
      updateRefereeGroupPageState(null); // set header
      return;
    }

    dispatch(fetchingGroupCommittee());
    return getRefereeGroupCommittee(slug)
      .then(response => {
        const {refereeGroup, referees} = response.data;
        checkApiRedirect(refereeGroup.slug, slug, null, null);
        updateRefereeGroupPageState(refereeGroup);
        dispatch(updateGroupCommittee(referees));
      })
      .catch(error => {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpErrorSubroute(errorData));
        }
      });
  };
}

export {
  fetchRefereeGroupSeasonSSR,
  fetchRefereeGroupSeason,
  fetchRefereeGroupHistorySSR,
  fetchRefereeGroupHistory,
  fetchRefereeGroupCommitteeSSR,
  fetchRefereeGroupCommittee,
  updateRefereeGroup,
  setRefereeGroupHeader,
};
