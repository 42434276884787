import {getTeam, fetchNextApiCall, api} from 'app/services/api';
import {changeHeaderText, changeHeaderEntity, changeHeaderFollowing} from 'app/actions/headerActions';
import {catchDataError, checkApiRedirect, RedirectException, parseLinkHeader} from 'app/helpers/apiHelpers';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {teamFilters, teamTransferFilters} from 'app/components/stream/streamFilter.const';
import {trySettingDefaultDistrict} from 'app/actions/sessionActions';
import {updateHistory} from 'app/actions/historyActions';
import {updateHttpError, updateHttpErrorSubroute} from 'app/components/error/ErrorHandlerActions';

const getTeamPlayers = teamSlug => {
  return api.get(`/teams/${teamSlug}/squad`);
};

const getTeamMatches = (teamSlug, pointer) => {
  const query = pointer === 'prev' ? `?flavor=past` : `?flavor=current`;
  let request = `/teams/${teamSlug}/matches${query}&limit=60`;
  return api.get(request);
};

const streamTypes = {
  news: 'news_published',
  'fupa-tv': 'playlist_published',
  gallery: 'gallery_published',
  transfer: 'profile_transfer',
  'coach-change': 'coach_changed',
  matchday: 'match_day_results',
  matchdelay: 'match_kickoff_changed',
  'final-score': 'match_result',
  ban: 'player_banned',
  injury: 'player_injured',
  matchevent: 'match_event',
};

const getTeamStream = (teamSlug, categories) => {
  const streamURL = process.env.STREAM_URL;
  let url = `${streamURL}/team-seasons/${teamSlug}?limit=18`;
  if (Object.keys(streamTypes).includes(categories)) {
    const type = streamTypes[categories];
    url += `&type=${type}`;
  }

  return api.get(url);
};

const getTeamHistory = teamSlug => {
  return api.get(`/teams/${teamSlug}/history`);
};

// TEAM_PAGE
function updateTeam(data) {
  return {
    type: 'TEAM_UPDATE',
    data,
  };
}

function setTeamHeader(dispatch, team) {
  if (!team) {
    return;
  }
  let parent = {
    name: 'ohne Wettbewerb',
    entity: null,
    slugs: {},
  };

  const teamLevel = team.level > 1 ? ` ${convertToRoman(team.level)}` : '';
  if (team.competition) {
    const {active, category, name, season, slug} = team.competition;
    const isLeague = category.id === 1;
    const competitionSeasonName = `${name} ${season.name}`;
    const competitionSeasonSlugs = active ? {} : {seasonSlug: season.slug};
    competitionSeasonSlugs[isLeague ? 'leagueSlug' : 'cupSlug'] = slug;

    parent = {
      name: competitionSeasonName,
      entity: isLeague ? 'league' : 'cup',
      slugs: competitionSeasonSlugs,
    };
  }

  dispatch(
    changeHeaderText({
      title: team.name.full + teamLevel,
      parent,
    })
  );
}

function fetchTeamMetaDataSSR(teamSlug) {
  return async function (dispatch, getState) {
    dispatch(changeHeaderFollowing(true));
    try {
      let response = await getTeam(teamSlug);
      const team = response.data;
      checkApiRedirect(team.slug, teamSlug, null, null, getState);
      dispatch(updateHistory('undefined', {store: 'TeamPage', data: team}));
      const teamName = team.level > 1 ? team.name.middle + ' ' + convertToRoman(team.level) : team.name.middle;
      dispatch(changeHeaderEntity({entity: 'team', name: teamName, slug: team.slug, teams: team.teams}));
      setTeamHeader(dispatch, team);
      // Return the Promise to make sure further handlers wait until it settles -> ssr rendered page is sent after setDefaultDistrict has finished
      if (team.competition) {
        return trySettingDefaultDistrict(team.competition, dispatch, getState);
      }
    } catch (error) {
      if (error instanceof RedirectException) {
        throw error;
      } else {
        const errorData = catchDataError(error);
        dispatch(updateHttpError(errorData));
      }
    }
  };
}

function fetchTeamMetaData(teamSlug, dispatchRedux, getState) {
  return function (dispatch, state) {
    dispatchRedux(changeHeaderFollowing(true));
    if (teamSlug === state?.slug) {
      setTeamHeader(dispatchRedux, state);
      return;
    }
    if (state?.status && state?.message) {
      return;
    }

    return getTeam(teamSlug)
      .then(response => {
        const team = response.data;
        checkApiRedirect(team.slug, teamSlug, null, null);
        dispatch(updateTeam(team));
        const teamName = team.level > 1 ? team.name.middle + ' ' + convertToRoman(team.level) : team.name.middle;
        dispatchRedux(changeHeaderEntity({entity: 'team', name: teamName, slug: team.slug, teams: team.teams}));
        setTeamHeader(dispatchRedux, team);
        if (team.competition) {
          return trySettingDefaultDistrict(team.competition, dispatchRedux, getState);
        }
      })
      .catch(function (error) {
        if (error instanceof RedirectException) {
          throw error;
        } else {
          const errorData = catchDataError(error);
          dispatchRedux(updateHttpError(errorData));
        }
      });
  };
}

// TEAM_PLAYERS_PAGE
function updateSquad(data) {
  return {
    type: 'TEAM_SQUAD_UPDATE',
    data,
  };
}

function fetchingSquad() {
  return {
    type: 'TEAM_SQUAD_FETCHING',
  };
}

function fetchTeamPlayers(teamSlug, forceRefresh, dispatchRedux) {
  return function (dispatch, state) {
    if (!forceRefresh && Object.keys(state.data.info).length) {
      return;
    }
    dispatch(fetchingSquad());
    return getTeamPlayers(teamSlug)
      .then(response => {
        dispatch(updateSquad(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchTeamPlayersSSR(teamSlug) {
  return function (dispatch) {
    dispatch(fetchingSquad());
    return getTeamPlayers(teamSlug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'TeamPlayersPage',
            data: {data: response.data, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

// TEAM_MATCHES_PAGE
function updateMatches(data) {
  return {
    type: 'TEAM_MATCHES_UPDATE',
    data,
  };
}
function errorMatches(data) {
  return {
    type: 'TEAM_MATCHES_ERROR',
    data,
  };
}

function fetchingMatches() {
  return {
    type: 'TEAM_MATCHES_FETCHING',
  };
}

function overwriteMatches(data) {
  return {
    type: 'TEAM_MATCHES_OVERWRITE',
    data,
  };
}

function fetchTeamMatches(teamSlug, pointer, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getTeamMatches(teamSlug, pointer);

    if (!fetchFunc) {
      return;
    }

    dispatch(fetchingMatches());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateMatches({items: response.data, nextUrl}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorMatches(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}
function fetchTeamMatchesSSR(teamSlug, pointer) {
  return function (dispatch) {
    return getTeamMatches(teamSlug, pointer)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;

        dispatch(
          updateHistory('undefined', {
            store: 'TeamMatchesPage',
            data: {items: response.data, nextUrl, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

// TEAM_NEWS_PAGE
function updateNews(data) {
  return {
    type: 'TEAM_NEWS_UPDATE',
    data,
  };
}

function errorNews(data) {
  return {
    type: 'TEAM_NEWS_ERROR',
    data,
  };
}

function fetchingNews() {
  return {
    type: 'TEAM_NEWS_FETCHING',
  };
}

function overwriteNews(data) {
  return {
    type: 'TEAM_NEWS_OVERWRITE',
    data,
  };
}

function fetchTeamNews(teamSlug, categories, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }

    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getTeamStream(teamSlug, categories, teamFilters);
    dispatch(fetchingNews());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateNews({items: response.data, nextUrl, categories}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorNews(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchTeamNewsSSR(teamSlug, categories) {
  return function (dispatch) {
    dispatch(fetchingNews());
    return getTeamStream(teamSlug, categories, teamFilters)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'TeamNewsPage',
            data: {items: response.data, nextUrl, categoryFilter: categories, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

// TEAM_TRANSFER_PAGE
function updateTransfers(data) {
  return {
    type: 'TEAM_TRANSFER_UPDATE',
    data,
  };
}

function errorTransfer(data) {
  return {
    type: 'TEAM_TRANSFER_ERROR',
    data,
  };
}

function fetchingTransfers() {
  return {
    type: 'TEAM_TRANSFER_FETCHING',
  };
}

function fetchTeamTransfers(teamSlug, reloading, dispatchRedux) {
  return function (dispatch, state) {
    if (!reloading && state.items.length) {
      return;
    }
    const {isFetching, nextUrl} = state;
    if (isFetching || nextUrl === null) {
      return;
    }
    const fetchFunc = nextUrl ? fetchNextApiCall(nextUrl) : getTeamStream(teamSlug, 'transfer', teamTransferFilters);
    dispatch(fetchingTransfers());
    return fetchFunc
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(updateTransfers({items: response.data, nextUrl}));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        nextUrl ? dispatch(errorTransfer(errorData)) : dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}
function fetchTeamTransfersSSR(teamSlug) {
  return function (dispatch) {
    dispatch(fetchingTransfers());
    return getTeamStream(teamSlug, 'transfer', teamTransferFilters)
      .then(response => {
        const meta = parseLinkHeader(response.headers.link);
        const nextUrl = meta.next ? meta.next : null;
        dispatch(
          updateHistory('undefined', {
            store: 'TeamTransferPage',
            data: {items: response.data, nextUrl, error: null, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

// TEAM_HISTORY_PAGE
function updateTeamHistory(data) {
  return {
    type: 'TEAM_HISTORY_UPDATE',
    data,
  };
}

function fetchingHistory() {
  return {
    type: 'TEAM_HISTORY_FETCHING',
  };
}

function fetchTeamHistory(teamSlug, dispatchRedux) {
  return function (dispatch, state) {
    if (state.items.length) {
      return;
    }

    dispatch(fetchingHistory());
    return getTeamHistory(teamSlug)
      .then(response => {
        dispatch(updateTeamHistory(response.data));
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatchRedux(updateHttpErrorSubroute(errorData));
      });
  };
}

function fetchTeamHistorySSR(teamSlug) {
  return function (dispatch) {
    return getTeamHistory(teamSlug)
      .then(response => {
        dispatch(
          updateHistory('undefined', {
            store: 'TeamHistoryPage',
            data: {items: response.data, isFetching: false},
          })
        );
      })
      .catch(error => {
        const errorData = catchDataError(error);
        dispatch(updateHttpErrorSubroute(errorData));
      });
  };
}

export {
  fetchTeamMetaDataSSR,
  fetchTeamMetaData,
  fetchTeamPlayersSSR,
  fetchTeamPlayers,
  fetchTeamMatchesSSR,
  fetchTeamMatches,
  fetchTeamNewsSSR,
  fetchTeamNews,
  fetchTeamTransfersSSR,
  fetchTeamTransfers,
  fetchTeamHistorySSR,
  fetchTeamHistory,
  overwriteNews,
  overwriteMatches,
};
