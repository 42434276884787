import React, {useReducer} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {renderRoutes} from 'react-router-config';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Helmet} from 'react-helmet-async';
import {object} from 'prop-types';
import {fetchTeamMetaData} from 'app/routes/team/TeamPageActions';
import {TeamPageReducer} from 'app/routes/team/TeamPageReducer';
import {TabBar} from 'app/components/tab/TabBar';
import {useHistory, useParams} from 'react-router';
import {useSiteSpecificAdTargeting} from 'app/components/ads/useAdTargeting';
import {useFetchData, useRestoreData, useEffectAfterMount} from 'app/hooks/dataHooks';
import {ErrorHandler} from 'app/components/error/ErrorHandler';
import formatTeamNameWithLevel from 'app/helpers/formatTeamNameWithLevel';
import {RedirectException} from 'app/helpers/apiHelpers';

const TeamPage = ({route}) => {
  const {restoredData} = useRestoreData('TeamPage');
  const {teamSlug} = useParams();
  const history = useHistory();
  const initialState = restoredData ?? {};
  const [state, dispatch] = useReducer(TeamPageReducer, initialState);
  const dispatchRedux = useDispatch();
  const {getState} = useStore();

  const loadData = () => fetchTeamMetaData(teamSlug, dispatchRedux, getState)(dispatch, state);
  useFetchData(state, loadData, 'TeamPage', true);

  const teamName = state.name?.full && state.level ? formatTeamNameWithLevel(state.name.full, state.level) : '';
  const competitionSeasonName = state.competition
    ? `${state.competition.name} ${state.competition.season.name}`
    : 'ohne Wettbewerb';
  const defaultTitle = `${teamName} - ${competitionSeasonName}${TITLE_POSTFIX}`;
  const titleTemplate = `%s${defaultTitle}`;
  const defaultMetaDesc = `${teamName} in der Liga ${competitionSeasonName} mit allen zugehörigen Statistiken inklusive Spielplan und Tabelle.`;
  const meta = [
    {name: 'description', content: defaultMetaDesc},
    {
      name: 'robots',
      content: 'noindex, follow',
    },
  ];

  useSiteSpecificAdTargeting({club: state.club?.slug, competition: state?.competition?.slug}, [
    state?.club,
    state?.competition,
  ]);

  // load new data when changing team (using HistoryPage or StreamPages)
  useEffectAfterMount(() => {
    loadData().catch(error => {
      if (error instanceof RedirectException) {
        // Make redirect triggered by Redirect from API
        history.replace(error.target);
      }
    });
  }, [teamSlug]);

  const TabNames = [
    {
      name: 'Kader',
      url: `/team/${teamSlug}`,
    },
    {
      name: 'Spiele',
      url: `/team/${teamSlug}/matches`,
    },
    {
      name: 'Spielerstatistik',
      url: `/team/${teamSlug}/playerstats`,
      hasSubUrl: true,
    },
    {
      name: 'News',
      url: `/team/${teamSlug}/news`,
      hasSubUrl: true,
    },
    {
      name: 'Transfers',
      url: `/team/${teamSlug}/transfers`,
    },
    {
      name: 'Chronik',
      url: `/team/${teamSlug}/history`,
    },
  ];

  return (
    <>
      <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} meta={meta} />
      <TabBar tabs={TabNames} />
      <ErrorHandler subroute={true}>
        {renderRoutes(route.routes, {team: state, defaultMetaDesc, dispatchRedux})}
      </ErrorHandler>
    </>
  );
};

TeamPage.propTypes = {
  route: object.isRequired,
};

export default TeamPage;
