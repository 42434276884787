const TeamPageReducer = (state, action) => {
  switch (action.type) {
    case 'TEAM_UPDATE':
      return action.data;
    default:
      return state;
  }
};

const TeamPlayersPageReducer = (state, action) => {
  switch (action.type) {
    case 'TEAM_SQUAD_FETCHING':
      return {...state, isFetching: true};
    case 'TEAM_SQUAD_UPDATE':
      return {...state, data: action.data, isFetching: false};
    default:
      return state;
  }
};

const TeamMatchesPageReducer = (state, action) => {
  switch (action.type) {
    case 'TEAM_MATCHES_FETCHING':
      return {...state, isFetching: true};
    case 'TEAM_MATCHES_OVERWRITE':
      return action.data;
    case 'TEAM_MATCHES_UPDATE':
      const TeamMatchesPage = {...state};
      TeamMatchesPage.isFetching = false;
      TeamMatchesPage.items = TeamMatchesPage.items.concat(action.data.items);
      TeamMatchesPage.nextUrl = action.data.nextUrl;
      return TeamMatchesPage;
    case 'TEAM_MATCHES_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: false,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const TeamNewsPageReducer = (state, action) => {
  switch (action.type) {
    case 'TEAM_NEWS_FETCHING':
      return {...state, isFetching: true};
    case 'TEAM_NEWS_UPDATE':
      const TeamNewsPage = {...state};
      TeamNewsPage.items = TeamNewsPage.items.concat(action.data.items);
      TeamNewsPage.nextUrl = action.data.nextUrl;
      TeamNewsPage.isFetching = false;
      TeamNewsPage.categoryFilter = action.data.categories;
      return TeamNewsPage;
    case 'TEAM_NEWS_OVERWRITE':
      return action.data;
    case 'TEAM_NEWS_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: null,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const TeamTransferPageReducer = (state, action) => {
  switch (action.type) {
    case 'TEAM_TRANSFER_FETCHING':
      return {...state, isFetching: true};
    case 'TEAM_TRANSFER_UPDATE':
      const TeamTransfersPage = {...state};
      TeamTransfersPage.items = TeamTransfersPage.items.concat(action.data.items);
      TeamTransfersPage.nextUrl = action.data.nextUrl;
      TeamTransfersPage.isFetching = false;
      return TeamTransfersPage;
    case 'TEAM_TRANSFER_ERROR':
      return {
        ...state,
        error: action.data,
        isFetching: null,
        nextUrl: null,
      };
    default:
      return state;
  }
};

const TeamHistoryPageReducer = (state, action) => {
  switch (action.type) {
    case 'TEAM_HISTORY_FETCHING':
      return {...state, isFetching: true};
    case 'TEAM_HISTORY_UPDATE':
      return {...state, items: action.data, isFetching: false};
    default:
      return state;
  }
};

export {
  TeamPageReducer,
  TeamPlayersPageReducer,
  TeamMatchesPageReducer,
  TeamNewsPageReducer,
  TeamTransferPageReducer,
  TeamHistoryPageReducer,
};
