const RefereeGroupPageReducer = (state, action) => {
  switch (action.type) {
    case 'REFEREE_GROUP_UPDATE':
      return action.data;
    default:
      return state;
  }
};

const RefereeGroupSeasonPageReducer = (state, action) => {
  switch (action.type) {
    case 'REFEREE_GROUP_SEASON_FETCHING':
      return {...state, isFetching: true};
    case 'REFEREE_GROUP_SEASON_UPDATE':
      const RefereeGroupSeasonPage = {...state};
      RefereeGroupSeasonPage.items = RefereeGroupSeasonPage.items.concat(action.data.items);
      RefereeGroupSeasonPage.nextUrl = action.data.nextUrl;
      RefereeGroupSeasonPage.isFetching = false;
      return RefereeGroupSeasonPage;
    case 'REFEREE_GROUP_SEASON_ERROR':
      return {
        ...state,
        isFetching: false,
        nextUrl: null,
        error: action.data,
      };
    default:
      return state;
  }
};

const RefereeGroupHistoryPageReducer = (state, action) => {
  switch (action.type) {
    case 'REFEREE_GROUP_HISTORY_FETCHING':
      return {...state, isFetching: true};
    case 'REFEREE_GROUP_HISTORY_UPDATE':
      const RefereeGroupHistoryPage = {...state};
      RefereeGroupHistoryPage.items = RefereeGroupHistoryPage.items.concat(action.data.items);
      RefereeGroupHistoryPage.nextUrl = action.data.nextUrl;
      RefereeGroupHistoryPage.isFetching = false;
      return RefereeGroupHistoryPage;
    case 'REFEREE_GROUP_HISTORY_ERROR':
      return {
        ...state,
        isFetching: false,
        nextUrl: null,
        error: action.data,
      };
    default:
      return state;
  }
};

const RefereeGroupCommitteePageReducer = (state, action) => {
  switch (action.type) {
    case 'REFEREE_GROUP_COMMITTEE_FETCHING':
      return {...state, isFetching: true};
    case 'REFEREE_GROUP_COMMITTEE_UPDATE':
      return {
        ...state,
        isFetching: false,
        items: action.data,
      };
    default:
      return state;
  }
};

export {
  RefereeGroupPageReducer,
  RefereeGroupSeasonPageReducer,
  RefereeGroupHistoryPageReducer,
  RefereeGroupCommitteePageReducer,
};
