import React, {useEffect, useMemo} from 'react';
import {renderRoutes} from 'react-router-config';
import {TITLE_POSTFIX} from 'app/seo/seo.constants';
import {Helmet} from 'react-helmet-async';
import {object} from 'prop-types';
import {TabBar} from 'app/components/tab/TabBar';
import {useSelector, useDispatch} from 'react-redux';
import {createSelector} from 'reselect';
import {useHistory, useParams} from 'react-router';
import {fetchSelectedDistrict} from 'app/actions/sessionActions';
import {ErrorHandler} from 'app/components/error/ErrorHandler';
import District from 'app/models/District';
import ExpFeatureNewDistrictPage from 'app/models/experimentalFeature/ExpFeatureNewDistrictPage';
import ExperimentalFeatureManager from 'app/models/experimentalFeature/ExperimentalFeatureManager';

const selectDistrict = createSelector(
  state => state.session,
  session => session.district
);

const DistrictPage = ({route}) => {
  const {slug, name} = useSelector(selectDistrict);
  const titleTemplate = `%s ${name}${TITLE_POSTFIX}`;
  const dispatch = useDispatch();
  const history = useHistory();
  const {districtSlug} = useParams();
  const isTestPage = ExperimentalFeatureManager.getInstance().isEnabled(new ExpFeatureNewDistrictPage());
  const district = useMemo(() => new District(districtSlug), [districtSlug]);

  useEffect(() => {
    // set header on back navigation
    dispatch(fetchSelectedDistrict(districtSlug, 'FuPa', history.location.pathname));
  }, []);

  const TabNames = [
    {
      name: 'News',
      url: district.getRootUrl(),
      rel: isTestPage ? 'nofollow' : 'follow',
    },
    {
      name: 'Börse',
      url: district.getSubUrl('marketplace'),
      hasSubUrl: true,
    },
    {
      name: 'Transfers',
      url: district.getSubUrl('transfers'),
    },
  ];

  return (
    <>
      <Helmet defaultTitle={`${name}${TITLE_POSTFIX}`} titleTemplate={titleTemplate} />
      <TabBar tabs={TabNames} />
      <ErrorHandler subroute={true}>{renderRoutes(route.routes, {name, slug, dispatchRedux: dispatch})}</ErrorHandler>
    </>
  );
};

DistrictPage.propTypes = {
  route: object.isRequired,
};

export default DistrictPage;
