import React, {useEffect, useState} from 'react';
import {COLORS, ELEVATIONS, SIZES} from '@fupa/fupa-uikit';
import {AppBar, Tabs} from '@mui/material';
import {useSelector} from 'react-redux';
import {selectCollapsedAppBar} from 'app/hooks/reduxCreateSelectorHooks';
import {TabItem} from 'app/components/tab/TabItem';
import {useHistory} from 'react-router';
import {zIndexNavigation} from 'app/styles/cssConstants';
import styled from 'styled-components';
import {styled as muiStyled} from '@mui/material/styles';

const MuiAppbar = muiStyled(AppBar)(
  ({theme, ...props}) => `
    background-color: ${props.withBg ? COLORS.lightestGrey : COLORS.white};
    top: ${
      !props.disableHeaderCollapsing ? `calc(${SIZES['72']} + env(safe-area-inset-top))` : `env(safe-area-inset-top)`
    };
    transform: ${props.collapsedAppBar && !props.disableHeaderCollapsing ? `translateY(-${SIZES['72']})` : 'none'};
    transition: transform 0.3s;
    z-index: ${zIndexNavigation};
    box-shadow: ${ELEVATIONS.AppBar};

    ${theme.breakpoints.up('md')} {
      display: 'flex';
      alignItems: 'center';
      top: ${props.disableHeaderCollapsing ? 0 : SIZES['72']};
      transform: none;
      // Show MatchFab correctly on MatchPages
      overflow-x: visible;
    };
  `
);

const Wrapper = styled.div`
  max-width: ${props => props.theme.desktopContentWidth}px;
  width: 100%;
  margin: 0 auto;
`;

const checkSubUrl = (currentTabUrl, tabs, pathname) => {
  const firstTab = tabs[0];
  const tabSlug = currentTabUrl.split(firstTab.url).join('');
  if (tabSlug) {
    if (pathname.includes(tabSlug)) {
      return true;
    }
  } else {
    const otherTabSlugs = tabs
      .map(tab => {
        return tab.url.split(firstTab.url).join('');
      })
      .filter(slug => slug);
    const slugsInUrl = otherTabSlugs.filter(slug => pathname.includes(slug));
    if (!slugsInUrl.length) {
      return true;
    }
  }
  return false;
};

const getActiveTab = (tabs, pathname) => {
  return tabs.findIndex(tab => {
    if (tab.url === pathname) {
      return true;
    } else if (tab.hasSubUrl) {
      return checkSubUrl(tab.url, tabs, pathname);
    } else {
      return false;
    }
  });
};

export const TabBar = ({children, disableHeaderCollapsing, replace, tabs, withBg}) => {
  const collapsedAppBar = useSelector(selectCollapsedAppBar);
  const {action, location} = useHistory();
  const [value, setValue] = useState(() => getActiveTab(tabs, location.pathname));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Change active tab on back-navigation
    if (action === 'POP') {
      const newIndex = getActiveTab(tabs, location.pathname);
      handleChange(null, newIndex);
    }
  }, [location.pathname]);

  return (
    <MuiAppbar
      id='fupa-tabbar'
      withBg={withBg}
      disableHeaderCollapsing={disableHeaderCollapsing}
      collapsedAppBar={collapsedAppBar}
      position={disableHeaderCollapsing ? 'static' : 'fixed'}>
      <Wrapper>
        <Tabs
          aria-label='subnavigation'
          sx={theme => ({
            maxWidth: theme.breakpoints.values.md,
            minHeight: SIZES[42],
            width: '100%',
          })}
          indicatorColor='secondary'
          scrollButtons={false}
          value={value}
          variant='scrollable'>
          {tabs.map((tab, tabIndex) => (
            <TabItem
              key={tab.name}
              onClick={handleChange}
              replace={replace}
              selected={tabIndex === value}
              tab={tab}
              tabIndex={tabIndex}
            />
          ))}
        </Tabs>
        {children}
      </Wrapper>
    </MuiAppbar>
  );
};
