import React from 'react';
import {FPNavLink} from 'app/components/links/link';
import {Tab} from '@mui/material';
import {COLORS, FONTS, SIZES} from '@fupa/fupa-uikit';
import {styled} from '@mui/material/styles';

const StyledMuiTab = styled(Tab)(
  ({theme}) => `
  color: ${COLORS.darkestGrey};
  font-size: ${FONTS['14']};
  flex-grow: 1;
  max-width: 50vw;
  min-width: 72px;
  min-height: ${SIZES[42]};
  '&:focus, &:hover, &.active': {
    background-color: ${COLORS.lighterGrey};
  };
  &.Mui-selected {
    color: ${COLORS.fupaSecondary};
  };

  ${theme.breakpoints.up('md')} {
      max-width: ${theme.breakpoints.values.md / 2}px;
    };
`
);

export const TabItem = ({onClick, replace, selected, tab, tabIndex}) => {
  const {name, url, search, hash, rel} = tab;

  return (
    <StyledMuiTab
      aria-controls={name}
      component={FPNavLink}
      disableRipple
      disableFocusRipple
      exact={true}
      id={`tab-${tabIndex}`}
      label={name}
      onClick={e => onClick(e, tabIndex)}
      replace={replace}
      tabIndex={0}
      to={{pathname: url, search: search, hash: hash}}
      wrapped={true}
      selected={selected}
      rel={rel}
    />
  );
};
